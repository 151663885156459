import * as React from "react";

import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useWindowSize } from "react-use";
import { useEffect } from "react";
import _ from "lodash";
import { Page } from "../components/Page";
import DefaultLayout from "../layouts/default";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import SmallerSectionTitle from "../components/Text/SmallerSectionTitle";
import PublicationsItem from "../components/Publications/PublicationsItem";
import { breakpoints } from "../styles/variables";

const PublicationsElem = styled.div`
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: 25px;
  margin-bottom: 69px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 110px;
    grid-column-gap: 100px;
  }
`;

interface PublicationProps {
  pageContext: {
    publicationArticles: any;
    publicationBooks: any;
  };
}

const Publications: React.FC<PublicationProps> = (props) => {
  const {
    pageContext: { publicationArticles, publicationBooks },
  } = props;

  const { width, height } = useWindowSize();

  function resizeGridItem(item) {
    const rowHeight = 20;
    const rowGap = 0;
    const rowSpan = Math.ceil(
      (item.querySelector(".content").getBoundingClientRect().height + rowGap) /
        (rowHeight + rowGap)
    );
    // alert("doing something");
    item.style.gridRowEnd = `span ${rowSpan}`;
  }

  function resizeAllGridItems() {
    const allItems = document.getElementsByClassName("item");
    for (let x = 0; x < allItems.length; x += 1) {
      resizeGridItem(allItems[x]);
    }
  }

  // function resizeInstance(instance) {
  //   const item = instance.elements[0];
  //   resizeGridItem(item);
  // }

  // for (let x = 0; x < allItems.length; x++) {
  //   imagesLoaded(allItems[x], resizeInstance);
  // }

  useEffect(() => {
    resizeAllGridItems();
  }, [width]);

  useEffect(() => {
    resizeAllGridItems();

    setTimeout(() => {
      resizeAllGridItems();
    }, 500);
  }, []);
  const sortedPublicationArticles = _.orderBy(
    publicationArticles,
    ["attributes.publishedFrom"],
    ["desc"]
  );
  const sortedPublicationBooks = _.orderBy(
    publicationBooks,
    ["attributes.publishedFrom"],
    ["asc"]
  );

  return (
    <DefaultLayout>
      <Helmet>
        <title>Publications - STEP</title>
      </Helmet>
      <Page>
        <ContentWrapper>
          <SmallerSectionTitle>
            STEP <b>PUBLICATIONS</b>
          </SmallerSectionTitle>

          <PublicationsElem className="publicationsGrid">
            {sortedPublicationArticles.map((publication) => (
              <PublicationsItem publication={publication} />
            ))}
          </PublicationsElem>
          <SmallerSectionTitle>
            STEP <b>BOOKS</b>
          </SmallerSectionTitle>
          <PublicationsElem className="publicationsGrid">
            {sortedPublicationBooks.map((publication) => (
              <PublicationsItem publication={publication} />
            ))}
          </PublicationsElem>
        </ContentWrapper>
      </Page>
    </DefaultLayout>
  );
};

export default Publications;
