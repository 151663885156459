import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const PublicationsItemElem = styled.article`
  > div {
    max-width: 288px;
    padding-bottom: 3px;
    border-bottom: 4px solid ${colors.blue};
    margin: 0 auto 30px;
    img {
      max-height: 120px;
      margin-bottom: 13px;
    }
    h3 {
      font: 32px / 38px "Slate Medium";
      margin-bottom: 4px;
      text-transform: uppercase;
    }
    p {
      font: 16px / 19px "Slate Light";
      margin-bottom: 10px;

      b,
      strong {
        font-family: "Slate Medium";
      }
    }
    a,
    p a {
      color: ${colors.lightBlue};
      text-decoration: none;
    }

    time {
      display: block;
      font: 12px / 20px "Slate Light";
      margin-bottom: 10px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    > div {
      h3 {
        font: 32px / 38px "Slate Medium";
        margin-bottom: 4px;
      }
      p,
      a,
      p a {
        font: 21px / 30px "Slate Light";
        margin-bottom: 13px;
      }

      time {
        font: 16px / 30px "Slate Light";
        margin-bottom: 4px;
      }
    }
  }
`;

const PublicationsItem = ({ publication }) => (
    <PublicationsItemElem className="item">
      <div className="content">
        <h3>{publication.attributes.name}</h3>
        {/* <time>January 2. 2021</time>
      <img src="http://placekitten.com/g/200/300" /> */}
        <p>
          <div
            dangerouslySetInnerHTML={{ __html: publication.attributes.teaser }}
          />
        </p>
        <p>
          <a href={publication.attributes.urlPath}>Read more</a>
        </p>
      </div>
    </PublicationsItemElem>
  );

export default PublicationsItem;
